import React from 'react';
import PropTypes from 'prop-types';
import {useFocusBlur} from 'utils/hooks';

const getStrings = (props, focus) => {
  const {value, id, name, autoComplete} = props;
  return {
    container: `form-group${focus || value ? ' do-float' : ''}`,
    name: name || id,
    autoComplete: autoComplete ? 'on' : 'off',
  };
};

const Input = props => {
  const {
    id,
    type,
    label,
    value,
    autoFocus,
    handleChange,
    naked,
    topLabel,
    min,
    max,
    maxLength,
  } = props;
  const {focus, handleFocus, handleBlur} = useFocusBlur(props);
  const {container, autoComplete, name} = getStrings(props, focus);

  return (
    <div className={naked ? 'Input' : container}>
      {topLabel && <label htmlFor={id}>{label}</label>}
      <input
        min={min}
        max={max}
        maxLength={maxLength}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={handleChange}
        className={naked ? '' : 'form-control'}
        type={type}
        id={id}
        name={name || id}
        autoComplete={autoComplete}
        value={value}
        autoFocus={autoFocus}
      />
      {!topLabel && <label htmlFor={id}>{label}</label>}
    </div>
  );
};

Input.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
  handleFocus: PropTypes.func,
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.oneOf(['text', 'email', 'password', 'number']).isRequired,
  autoComplete: PropTypes.bool,
  autoFocus: PropTypes.bool,
  naked: PropTypes.bool,
  topLabel: PropTypes.bool,
  maxLength: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
};

Input.defaultProps = {
  type: 'text',
  value: '',
  autoComplete: false,
  autoFocus: false,
  naked: false,
  topLabel: false,
  maxLength: null,
  min: null,
  max: null,
};

export default Input;
