import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {useFocusBlur} from 'utils/hooks';

export const Root = styled.div`
  margin-bottom: 20px;
`;
export const getStrings = ({id, name, type, value}) => ({
  container: `custom-control custom-${type}`,
  name: name || id,
  value: value || id,
});

const CheckInput = props => {
  const {id, type, label, autoFocus, handleChange, checked} = props;
  const {handleFocus, handleBlur} = useFocusBlur(props);
  const {container, name, value} = getStrings(props);
  return (
    <Root className={container}>
      <input
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={handleChange}
        className="custom-control-input"
        type={type}
        id={id}
        name={name || id}
        value={value}
        checked={checked}
        autoFocus={autoFocus}
      />
      <label className="custom-control-label" htmlFor={id}>
        {label}
      </label>
    </Root>
  );
};

CheckInput.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
  handleFocus: PropTypes.func,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.oneOf(['radio', 'checkbox']).isRequired,
  autoFocus: PropTypes.bool,
  naked: PropTypes.bool,
  checked: PropTypes.bool,
};

CheckInput.defaultProps = {
  type: 'checkbox',
  value: '',
  autoFocus: false,
  naked: false,
  checked: false,
};

export default CheckInput;
